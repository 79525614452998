import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {serializeAxiosError} from "./reducer.utils";
import axios from "axios";

export type SeoState = Readonly<typeof seoInitialState>;

export const seoInitialState = {
    loading: false,
    meta: {} as any,
};


export const getSeo = createAsyncThunk(
    'seo/get',
    async => axios.get<any[]>(`/api/meta`,
        {
            headers: {
                'Cache-Control': 'no-cache'
            }
        }
    ),
    {serializeError: serializeAxiosError}
);

export const SeoSlice = createSlice({
        name: 'order',
        initialState: seoInitialState as SeoState,
        reducers: {
            reset() {
                return seoInitialState;
            },
        },
        extraReducers(builder) {
            builder
                .addCase(getSeo.pending, (state) => {
                    state.loading = true;
                })
                .addCase(getSeo.fulfilled, (state, action) => {
                    state.loading = false;
                    state.meta = action.payload.data;
                })
                .addCase(getSeo.rejected, (state, action) => {
                    state.loading = false;
                })
            ;
        },
    })
;

export const {reset} = SeoSlice.actions;

// Reducer
export default SeoSlice.reducer;
